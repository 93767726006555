/* =============================================================================
 * Reset
============================================================================= */

* {
	box-sizing:border-box;
	margin:0;
	padding:0;
	font:inherit;
	font-weight:inherit;
	font-style:inherit;
}

html {
	height:100%;
    font-size: 100%;
}
.c-hero-image__heading {
	margin-bottom:var(--spacing-sm);
    font-size: 1.25rem;
	color: var(--color-white);
	@media(--sm) {
		font-size:1.25rem;
	}
	@media(--md) {
		font-size: 3rem;
	}
}

.c-hero-image__container{
    padding-left: var(--spacing);
    padding-right: var(--spacing);
}

.c-hero-image__text {
	font-size: 1rem;
	color: var(--color-white);
}

.c-hero-image__content p {
	text-shadow:none;
	@media(--md) {
		font-size:1em;
		font-weight:inherit;
	}
}

.c-hero-image__primary-button{
	margin-top: var(--spacing);
	@media(--md) {
		margin-top: 0;
	}
}
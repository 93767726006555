/* =============================================================================
 * Width limiter
 *
 * Limits width and centers container on page.
============================================================================= */
@define-mixin width-limiter {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: calc(var(--gutter) / 2);
	padding-left: calc(var(--gutter) / 2);

	@media (--md) {
		max-width: calc(1128px + (var(--gutter-md) * 2));
		padding-left: var(--gutter-md);
		padding-right: var(--gutter-md);
	}
	@media (--lg) {
		max-width: calc(1128px + (var(--gutter-lg) * 2));
		padding-left: var(--gutter-lg);
		padding-right: var(--gutter-lg);
	}
}

.o-width-limiter {
	@mixin width-limiter;
}

.o-width-limiter--collpapsed {
	padding-right: 0px;
	padding-left: 0px;
}

/* =============================================================================
 * Button
 *
 * 1. Needed to reset any styles set on a:visited.
 * TODO:Hover effects
============================================================================= */

.c-button,
.c-button:visited /* [1] */ {
  background-color: transparent;
  border-radius: var(--spacing);
  color: var(--color-black);
  cursor: pointer;
  outline: none;
  width: auto;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-family: 'Lab-grotesque', sans-serif;
  transition: all 0.16s ease;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.05em;
  cursor: pointer;
  border: none;
  padding: 1em;
  font-size: 1rem;
  margin: 0;
  line-height: 1;
}

.c-button + .c-button {
  margin-left: 0;
}

.c-button + .c-button--primary {
  margin-left: 0;
}

.c-button--tertiary + .c-button--primary {
  margin-left: var(--spacing-s);
}

@media (--sm) {
  .c-button + .c-button {
    margin-left: var(--spacing-s);
  }

  .c-button + .c-button--primary {
    margin-left: var(--spacing-sm);
  }
}

.c-button[disabled] {
  cursor: not-allowed;
}

@media (--md) {
  .c-button,
  .c-button:visited {
    padding: var(--spacing-sm);
  }
}

.c-button--small {
  font-size: 0.875rem;
  padding: 0.64286em 0.75rem;
}

.c-button--big {
  font-size: 1.5rem;
  padding: 0.833335em 1em;
}

/* Primary
 ---------------------------------------------------------------------------- */
.c-button--primary,
.c-button--primary:visited {
  display: block;
  background-color: var(--color-primary-400);
  color: var(--color-white);
  text-decoration: none;
  border: none;
  font-weight: 500;
  margin: 0;
}

.c-button--primary:hover {
  background-color: var(--color-primary-500);
  box-shadow: none;
  text-decoration: none;
}

.c-button--primary:focus {
  background-color: var(--color-primary-400);
  box-shadow: 0 0 0 3px rgb(117, 185, 78);
  text-decoration: none;
}

.c-button--primary:active {
  background-color: var(--color-primary-300);
  text-decoration: none;
}

.c-button--primary[disabled],
.c-button--primary[disabled]:hover {
  background-color: var(--color-neutral-400);
  color: var(--color-white);
  box-shadow: none;
}

@media (--sm) {
  .c-button--primary,
  .c-button--primary:visited {
    display: inline-block;
  }
}

/* Secondary
 ---------------------------------------------------------------------------- */
.c-button.c-button--secondary,
.c-button.c-button--secondary:visited {
  display: block;
  box-shadow: inset 0 0 0 1px var(--color-primary-400);
  color: var(--color-primary-400);
  font-weight: 500;
}

.c-button.c-button--secondary:hover {
  background-color: var(--color-primary-100);
  background-clip: padding-box;
  box-shadow: none;
  text-decoration: none;
}

.c-button--secondary:focus {
  background-color: var(--color-primary-100);
  box-shadow: 0 0 0 3px rgb(117, 185, 78);
  border: none;
  text-decoration: none;
}

.c-button--secondary:active {
  background-color: var(--color-primary-200);
  text-decoration: none;
}

.c-button--secondary[disabled],
.c-button--secondary[disabled]:hover {
  background: none;
  box-shadow: inset 0 0 0 1px var(--color-neutral-400);
  color: var(--color-neutral-400);
  box-shadow: none;
}

@media (--sm) {
  .c-button.c-button--secondary,
  .c-button.c-button--secondary:visited {
    display: inline-block;
  }
}
/* Tertiary
 ---------------------------------------------------------------------------- */
.c-button--tertiary,
.c-button--tertiary:visited {
  text-decoration: none;
  border: none;
  color: var(--color-primary-400);
}

.c-button--tertiary:hover {
  background-color: var(--color-primary-100);
  color: var(--color-primary-400);
  box-shadow: none;
  text-decoration: none;
}

.c-button--tertiary:focus {
  box-shadow: 0 0 0 3px rgb(117, 185, 78);
  border: none;
  text-decoration: none;
}

.c-button--tertiary:active {
  background-color: var(--color-primary-200);
  text-decoration: none;
}

.c-button--tertiary[disabled],
.c-button--tertiary[disabled]:hover {
  background: none;
  color: var(--color-neutral-400);
}

/* Light
 ---------------------------------------------------------------------------- */
 .c-button--light,
 .c-button--light:visited {
   display: block;
   background-color: var(--color-white);
   color: var(--color-primary-400);
   text-decoration: none;
   border: none;
   font-weight: 500;
   margin: 0;
 }
 
 .c-button--light:hover {
   background-color: var(--color-neutral-100);
   box-shadow: none;
   text-decoration: none;
 }
 
 .c-button--light:focus {
   background-color: var(--color-white);
   box-shadow: 0 0 0 3px rgb(117, 185, 78);
   text-decoration: none;
 }
 
 .c-button--light:active {
   background-color: var(--color-neutral-200);
   text-decoration: none;
 }
 
 .c-button--light[disabled],
 .c-button--light[disabled]:hover {
   background-color: var(--color-neutral-300);
   color: var(--color-neutral-500);
   box-shadow: none;
 }
 
 @media (--sm) {
   .c-button--light,
   .c-button--light:visited {
     display: inline-block;
   }
 }

 /* Light inverted (does not exist in design system and shouldn't. 
 Light inverted is a temporary button that was needed in hero before redesign of some pages)
 ---------------------------------------------------------------------------- */
.c-button.c-button--light-inverted,
.c-button.c-button--light-inverted:visited {
  display: block;
  box-shadow: inset 0 0 0 1px var(--color-white);
  color: var(--color-white);
  font-weight: 500;
}

.c-button.c-button--light-inverted:hover {
  background-color: var(--color-primary-100);
  background-clip: padding-box;
  box-shadow: none;
  text-decoration: none;
  color: var(--color-primary-400);
}

.c-button--light-inverted:focus {
  background-color: var(--color-primary-100);
  box-shadow: 0 0 0 3px rgb(117, 185, 78);
  border: none;
  text-decoration: none;
  color: var(--color-primary-400);
}

.c-button--light-inverted:active {
  background-color: var(--color-primary-200);
  text-decoration: none;
  color: var(--color-primary-400);
}

.c-button--light-inverted[disabled],
.c-button--light-inverted[disabled]:hover {
  background: none;
  box-shadow: inset 0 0 0 1px var(--color-neutral-400);
  color: var(--color-neutral-400);
  box-shadow: none;
}

@media (--sm) {
  .c-button.c-button--light-inverted,
  .c-button.c-button--light-inverted:visited {
    display: inline-block;
  }
}

/* Product buttons */
.c-product__content .c-button.c-button--round {
  width: 1em;
  height: 1em;
  color: var(--color-grass);
  text-decoration: none;
  border-radius: 50%/50%;
}

.c-product__content .c-button.c-button--round:hover {
  background-color: var(--color-white);
  background-clip: padding-box;
  box-shadow: none;
  text-decoration: none;
}

.c-product__content .c-button.c-button--round .c-button__icon {
  margin-top: -1em;
  margin-left: -0.6175em;
}

.c-button.product-list__button {
  position: inherit;
}

/* =============================================================================
 * Custom Select
============================================================================= */
.c-select__wrapper {
  @mixin box-shadow 0.05;
  background: var(--color-white);
  border: 1px solid var(--color-white);
  overflow: hidden;
  width: 100%;
  transition: var(--transition);
  position: relative;
  border: 1px solid var(--color-prussian-blue);
  border-radius: 0.5rem;
}

.c-select__wrapper::after {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDI0Ij48ZyBpZD0iUGFnZS0xIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjxnIGlkPSJpY29uLWV4cGFuZC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMiAyKSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9ImN1cnJlbnRDb2xvciI+PHBhdGggaWQ9IlNoYXBlIiBkPSJNMCAwbDEwIDEwTDIwIDAiLz48L2c+PC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  height: 2.625rem;
  position: absolute;
  pointer-events: none;
  width: 38px;
  transition: var(--transition);
  top: 3px;
  right: 0;
}

.c-select__label {
  color: var(--color-main-label);
  position: relative;
  top: 0;
  left: 0;
  font-size: 1rem;
  margin-bottom: var(--spacing);
  z-index: auto;
}

.c-select__input {
  border: 1px solid var(--color-prussian-blue);
  border-radius: 34px;
  display: block;
  border: none;
  padding: 0 0 0 var(--spacing-sm);
  background-color: var(--color-white);
  height: var(--spacing-l);
  outline: 0;
  color: var(--color-black);

  /* Hide default select arrows */
  width: 105%;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

.c-select__wrapper:focus-within {
  border-color: var(--color-turtle-green);
}

.c-select__input:disabled {
	padding-top: 0;
	padding-bottom: 0;
}

@media (--md) {
	.c-select__wrapper::after {
		background-position: calc(100% - 18px) 50%;
	}
}

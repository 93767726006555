/* Text input fields
-------------------------------------------------------------- */
.c-rect-input__input:not([type='file']) {
	border:1px solid var(--color-prussian-blue);
	border-radius: 0.5rem;
	box-shadow: none;
	padding: 0 var(--spacing-sm);
}

.c-rect-input__input:valid{
	border-color: var(--color-prussian-blue);
}

.c-rect-input__input:focus, 
.c-rect-input__input:hover {
    border-color: var(--color-turtle-green);
}

.c-rect-input__input:not(.c-rect-input__input--small):valid,
.c-rect-input__input:not(.c-rect-input__input--small):focus {
	padding: 0 var(--spacing-s);
}

.c-rect-input__input:not(textarea):not([type='file']){
	height: var(--spacing-l);
}

.c-rect-input__label{
	position: relative;
	top: 0;
	left: 0;
    margin-bottom: var(--spacing);
}


/* =============================================================================
 * Combo box
 * 1. Left & right -1px makes the borders overlap.
 * 2. Make button cover whole area and make it clickable
============================================================================= */
.c-combo-box[class] {
	position:relative;
	background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDI0Ij48ZyBpZD0iUGFnZS0xIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjxnIGlkPSJpY29uLWV4cGFuZC0xNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMiAyKSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9ImN1cnJlbnRDb2xvciI+PHBhdGggaWQ9IlNoYXBlIiBkPSJNMCAwbDEwIDEwTDIwIDAiLz48L2c+PC9nPjwvc3ZnPg==);
	background-color:var(--color-white);
	background-repeat:no-repeat;
	background-position:calc(100% - 12px) 20px;
	padding:21px 0 3px 0;
	position:relative;
	transition:var(--transition);
	border-radius: 0.5rem;
    border: 1px solid var(--color-prussian-blue);
}

.c-combo-box[class].combobox--open{
	border-radius: 0.5rem 0.5rem 0 0;
}

.c-combo-box:hover,
.c-combo-box:focus,
.c-combo-box:focus-within {
	border-color:var(--color-turtle-green);
}

.c-combo-box__inner {
	display:block;
	width:95%;
}

.c-combo-box__label {
	@mixin text-style-zeta;
	color:var(--color-battleship-grey);
	position:absolute;
	top:6px;
	left: var(--spacing-sm);
	cursor:pointer;
}

.c-combo-box__content {
	display:none;
	background-color:var(--color-white);
	border-top-width:0;
	position:absolute;
	padding:var(--spacing);
	z-index:100;
	left:-1px; /* [1] */
	right:-1px; /* [1] */
	-ms-transform:translateY(3px);
	transform:translateY(3px);
	border-radius: 0 0 0.8rem 0.8rem;
    border-bottom: 1px solid var(--color-turtle-green);
    border-left: 1px solid var(--color-turtle-green);
    border-right: 1px solid var(--color-turtle-green);
}

.c-combo-box__content--border-top {
	border-top-width:1px;
}

.c-combo-box__trigger {
	width:100%;
	text-align:left;
	padding-left:var(--spacing-sm);
    text-decoration: none;
    border-radius: 0;
    border-width: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 21px;
    margin-top: -21px;
    padding-bottom: 3px;
    margin-bottom: -3px;
    line-height: 1.6;
    background-color: transparent;
    cursor: pointer;
}

@media(--md) {
	.c-combo-box[class] {
		padding:29px 0 6px 0;
		background-position:calc(100% - 18px) 26px;
	}
	.c-combo-box__content {
		-ms-transform:translateY(6px);
		transform:translateY(6px);
		padding:var(--spacing-sm);
	}

	.c-combo-box__trigger {
		padding-top:29px; /* [2] */
		margin-top:-29px; /* [2] */
		padding-bottom:6px; /* [2] */
		margin-bottom:-6px; /* [2] */
	}
}

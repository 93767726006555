  .c-network-cable-calculator .c-rect-input__label {
    transform: translate3d(0, 0, 0);
    margin-top: -1.3125rem;
    font-size: 16px;
    position: absolute;
  }

  .c-network-cable-calculator .c-rect-input__input:valid~.c-rect-input__label,
  .c-network-cable-calculator .c-rect-input__input:focus~.c-rect-input__label,
  .c-network-cable-calculator .c-rect-input__input.has-value~.c-rect-input__label  {
     transform: translate3d(0, 0, 0);
     font-size: 16px;
  }
  
  .c-network-cable-calculator ul {
      margin-left: 0;
      list-style: none;
  }

  .c-network-cable-calculator .c-form__item {
    margin-top: 2.625rem;
  }
  
  .c-network-cable-calculator .editorial-content .c-info-box, .editorial-content ul {
      margin-bottom: 0;
  }

  .c-network-cable-calculator {
    padding-top: 1rem;
  }
  .c-network-cable-calculator__extra--label {
    font-size: 0.75rem;
  }

  .c-network-cable-calculator .c-dropdown-variations.c-form__item {
    margin-top: 2.875rem;
  }

  .c-network-cable-calculator .c-button {
    margin-top: 1.5rem;
  }
.toasted .primary,
.toasted.toasted-primary {
    padding: 1em 2em !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    color: #000 !important;
}

.toasted .action {
    color: #000 !important;
}

.toasted .primary.error,
.toasted.toasted-primary.error {
    background:var(--color-dusty-orange) !important;
}
@font-face {
	font-weight: 400;
	font-style: italic;
	font-family: 'Open Sans';
	font-display: optional;
	src: url('../css/fonts/OpenSans-italic.eot');
	src: url('../css/fonts/OpenSans-italic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/OpenSans-italic.woff2') format('woff2'), 
			url('../css/fonts/OpenSans-italic.woff') format('woff'), 
			url('../css/fonts/OpenSans-italic.ttf') format('truetype');
}

/*
 * Text styles
 */
@define-mixin text-style-mixin-base {
    background: transparent;
    text-transform: none;
    text-shadow: none;
    word-spacing: normal;
    letter-spacing: normal;
}

@define-mixin text-style-reset {
    @mixin text-style-mixin-base;
    color: var(--color-black);
    font: var(--type-defaults);
    line-height: var(--type-line-height-default);
}

.preamble {
    font-family: var(--type-family-default);
	font-weight:400;
    font-size: 1.125rem;
    line-height: 1.5;
    margin-top: var(--spacing-s);
}

h1 {
    font-size: 2rem;
    font-family: var(--type-family-default);
    font-style: normal;
    font-weight: 700;
    line-height: 1.35;
    color: var(--color-dark-blue);
}

h2 {
    font-size: 1.5rem;
    font-family: var(--type-family-default);
    font-style: normal;
    font-weight: 500;
    line-height: 1.35;
    color: var(--color-dark-blue);
}

h3 {
    font-size: 1.25rem;
    font-family: var(--type-family-default);
    font-style: normal;
    font-weight: 500;
    line-height: 1.35;
    color: var(--color-dark-blue);
}

@media(--md){
    h1{
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    .preamble {
        font-size: 1.25rem;
    }
}
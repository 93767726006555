@define-mixin quote $selector {
	$(selector) {
		font-family:var(--type-family-secondary);
	}
}

@define-mixin borderedquote $selector{
	$(selector){
		font-family:var(--type-family-secondary);
		font-size:1.5rem;
		font-style: italic;
		line-height:2.125rem;
		text-align: center;
		color:var(--color-dark-blue);
		border:solid var(--color-light-grey-blue);
		border-width:1px 0;
		padding:var(--spacing-l) 0;	
		margin:var(--spacing-l) 0;
		
		@media(--sm){
			font-size:2.5rem;
			line-height:3.375rem;
		}
	}
}

@mixin quote c-quoute;
    .video-player__topic-heading {
    font-family: Lab-grotesque, Helvetica,sans-serif;
    text-transform: none;
    font-weight: 700;
    font-size: 1.25rem;
    color: #3B4D47;
    letter-spacing: normal;
    }

    .lSSlideOuter .lSPager.lSGallery li.active {
        border-radius: 0;
    }

    .lSSlideOuter .lSPager.lSGallery img {
    width: 163px;
    }

    .lSSlideOuter .lSPager.lSGallery li:hover {
        border-radius: 0;
    }

    .lSSlideOuter .lSPager.lSpg > li {
        padding: 0;
    }

    .lSPager .lSGallery {
        margin-top: .5rem !important;
    }


    @media(--md) {
        .video-player__topic-heading {
            font-size: 1.5rem;
        }
    }
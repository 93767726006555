.thumbnail {
	content:"";
	cursor:pointer;
	width:100%;
	height:100%;
	position:absolute;
	overflow:hidden;
	z-index:1;
}

.thumbnail span {
	color:var(--color-white);
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin: 0;
	box-shadow: none;
	text-decoration: none;
	border: none;
	font-weight: bold;
	text-align: unset;
	cursor: pointer;
	padding: 0;
	height: 100%; 
	width: 100%;
	border-radius: 0; 
	background: rgba(32, 35, 37, 0.5); 
}

.thumbnail span::before { 
    content: url('data:image/svg+xml;utf8,<svg width="48" height="48" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM42 58.3923L60 48L42 37.6077V58.3923Z" fill="white"/></svg>');
	display:inline-block;
	width: 48px;
	height: 48px;
	border-top:0 solid transparent;
	border-left:0 solid transparent; 
	border-bottom:0 solid transparent; 
	vertical-align:bottom;
	position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
}

.thumbnail:hover span {
	box-shadow:none;
	text-decoration:none;
}

.c-teaser__video-description {
	display: block;
	padding: 1em 0 0.2em 1em;
}

.c-teaser__video-description .c-teaser__video-heading {
	font-size: 1.5rem;
	margin-bottom: 0.3125rem;
	font-weight: 700;
}

.c-teaser__video-text  { 
	font-size: 0.875rem;
	font-weight: 400;
}

.light-slider .c-teaser__video-description {
	display: none;
}

.light-slider .c-teaser__video-text {
	display: none;
}

.light-slider .c-teaser__video-description .c-teaser__video-heading {
	display: none;
}


@media(--md) {
	.thumbnail span {
		height: 100%;
		width: 100%;
		border-radius: 0;
		background: rgba(32, 35, 37, 0.5);
	}

	.thumbnail span::before {
		content: url('data:image/svg+xml;utf8,<svg width="96" height="96" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM42 58.3923L60 48L42 37.6077V58.3923Z" fill="white"/></svg>');
		width: 96px;
		height: 96px;
	}

	.c-teaser__video-description {
		padding: 1.5em 2em;
	}

	.c-teaser__video-description .c-teaser__video-heading {
		font-size: 2rem;
		margin-bottom: 0.3125rem;
		font-weight: 700;
	}

	.c-teaser__video-text  { 
		font-size: 1.25rem;
		font-weight: 400;
	}

	.light-slider .c-teaser__video-description {
			display: block;
			padding: .5em 1em;
	}
	
	.light-slider .c-teaser__video-text  { 
			display: block;
			font-size: 20px;
			font-weight: 400;
		}

	.light-slider .c-teaser__video-description .c-teaser__video-heading {
		display: block;
		font-size: 2rem;
		margin-bottom: 0.3125rem;
		font-weight: 700;
	}
	
	.light-slider-container .lSPager.lSGallery {
		margin-top: .5rem !important;
	}

}
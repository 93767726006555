.c-product__content {
	background-color: var(--color-light-background);
}

.c-product__heading  h3{
	color:var(--color-white);
	font-size: 1rem;

	@media(--md) {
		font-size: 1.25rem;
	}
}
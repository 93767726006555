/* =============================================================================
 * Checkbox
 *
 * 1. Adds 1px on top and bottom padding to avoid jumpy behaviour when checked
============================================================================= */

.c-checkbox__label,
.c-checkbox-group__header__label {
	display:inline-block;
	position:relative;
	padding:calc(var(--spacing) + 1px) 0 calc(var(--spacing) + 1px) var(--spacing-xl); /* [1] */
	vertical-align:middle;
	white-space:normal;
	width:100%;
}
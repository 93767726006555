a {
	color:var(--color-primary-400);
	text-decoration: underline;
	transition:color 0.15s ease;
}

a:hover {
	text-decoration:none;
	color:var(--color-primary-500);
}

a:active {
	text-decoration:none;
	color:var(--color-primary-300);
}

a:focus-visible {
	text-decoration:none;
	color:var(--color-primary-400);
	outline: 2px solid var(--color-turtle-green);
	border-radius: 0.4rem;
}
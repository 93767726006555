.c-accordion__fold {
	background-color: var(--color-beige-background);
	margin-bottom:var(--spacing-xs);
	background-image: none;
	box-shadow: none;
}

.c-accordion__label {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-align:center;
	align-items:center;
	padding:var(--spacing-m);
	cursor:pointer;
	transition:var(--transition);
}

.c-accordion__locked-label {
	padding:var(--spacing-m) var(--spacing-s);
}

.c-accordion__locked-title {
	padding-left:var(--spacing-s);
}

.c-accordion__title {
	padding-right:var(--spacing-s);
}

.c-accordion__title--fullWidth {
	width: 100%;
}

.c-accordion__header {
	text-align:right;
	margin-bottom:var(--spacing-s);
}

.c-accordion__header__action {
	padding-bottom:var(--spacing-s);
}

.c-accordion__heading {
	margin-bottom:var(--spacing-s);
	font-size: 1.5rem;
	padding-left: var(--spacing-m);
	padding-top: var(--spacing-sm);
}

.c-accordion__expander,
.c-accordion__expander--expanded {
	width:1.5rem;
	height:1.5rem;
	transition:var(--transition);
}

.c-accordion__expander .c-icon svg{
	color: var(--color-black)
}

.c-accordion__content {
	padding:var(--spacing-s) var(--spacing-m) var(--spacing-xl);
}


.c-accordion__fold .c-table-list__item {
	padding:var(--spacing-s);
}

/* =============================================================================
 * Search form
============================================================================= */
.c-search-form {
  z-index: 1;
}

.c-search-form__field {
  box-shadow: none;
  min-height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-black);
}

.c-search-form__input {
  height: 2.5rem;
  border-radius: 0.5rem;
  min-height: 2.5rem;
}

.c-form .c-search-form__input {
  box-shadow: none;
  @media (--md) {
    height: 2.5rem;
  }
}

.c-search-form__icon {
  color: var(--color-black);
}
